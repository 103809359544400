<template>
  <div>
    <base-list-view
      @onRefresh="init"
      :dataSource="dataSource"
      :headers="headers"
      :loading="loading"
      :options="options"
      :showDelete="false"
      title="Мои курсы"
      :notShowActions="['add']"
      notShowSelect
      label-text-search="Поиск курса"
      pageEditName="KnowledgeBaseCourseEmployeeEdit"
      id="knowledge-base__courses"
      :funcHandleClickId="handleClick"
      sort-by="Name"
      :exclude-settings-actions="['group']"
      :showFilters="true"
    >
      <template slot="table" slot-scope="{ attrs, listeners }">
        <base-tree-table
          v-bind="attrs"
          v-on="listeners"
          :items="dataSource"
          fixedWidthActions
          :item-class="
            (item) => {
              return (
                (item.IsAvailable ? '' : 'disabled') +
                (item.IsNotified && 'font-weight-bold')
              );
            }
          "
          treeTableContents
        >
          <template slot="item.StatusText" slot-scope="{ item }">
            <div
              v-if="!item.Type"
              class="knowledge-base__status-point__wrapper"
            >
              <span
                class="knowledge-base__status-point"
                :class="'knowledge-base__status-point--' + item.Status"
              ></span>
              {{ item.StatusText }}
            </div>
          </template>
          <template slot="item.Period" slot-scope="{ item }">
            <div style="width: 150px">
              <template v-if="item.IsLimited">
                <span
                  :class="{
                    'error--text':
                      item.Status !== 3 &&
                      moment(item.Period.DateOut, 'DD.MM.YYYY') < moment(),
                  }"
                >
                  {{ PeriodFormat(item.Period.DateIn, item.Period.DateOut) }}
                </span>
              </template>
            </div>
          </template>
          <template slot="item.Curators" slot-scope="{ item }">
            <div style="width: 150px">
              {{
                item.Curators
                  ? item.Curators.map((e) =>
                      $options.filters.PersonShortName(e)
                    ).join(", ")
                  : ""
              }}
            </div>
          </template>
          <template slot="item.Subject" slot-scope="{ item }">
            <div class="td1-wrap">
              <m-icon
                v-if="item.Type && item.Type === 0"
                icon="medexpert-file-test"
                class="mr-4"
              ></m-icon>
              <m-icon
                v-if="item.Type && item.Type === 1"
                icon="medexpert-file-word"
                class="mr-4"
              ></m-icon>
              <m-icon
                v-if="item.Type && item.Type === 2"
                icon="medexpert-file-doc"
                class="mr-4"
              ></m-icon>
              <m-icon
                v-if="item.Type && item.Type === 3"
                icon="medexpert-file-video"
                class="mr-4"
              ></m-icon>
              <span v-if="item.Subject && !item.Type">{{ item.Subject }}</span>
            </div>
          </template>
        </base-tree-table>
      </template>
    </base-list-view>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import KnowledgeBase from "@/services/KnowledgeBaseService";
import BaseListView from "../../layouts/BaseListView.vue";
import BaseTreeTable from "../../components/base/BaseTreeTable.vue";
import ListEditMixin from "@/mixins/ListEditMixin";

export default {
  name: "knowledgebase-my",
  components: {
    BaseListView,
    BaseTreeTable,
  },
  mixins: [ListEditMixin],
  data() {
    return {
      apiService: KnowledgeBase.сourseEmployee,
      headers: [
        {
          text: "Название курса",
          value: "Name",
          width: "100%",
          notGroup: true,
        },
        {
          text: "Тематика / Вид учебного материала",
          value: "Subject",
          align: "center",
          cellClass: "td-minimum td1",
        },
        {
          text: "Период",
          value: "Period",
          width: "15%",
          align: "center",
          cellClass: "td-minimum td5",
          dataType: "Period",
        },
        {
          text: "Кураторы",
          value: "Curators",
          align: "center",
          cellClass: "td3",
          dataType: "array",
          displayText: (e) => this.$options.filters.PersonShortName(e),
        },
        {
          text: "Статус",
          value: "StatusText",
          width: "1%",
          cellClass: "td-minimum td2",
        },
      ],
      loading: true,
      dataSource: { Period: { DateIn: null, DateOut: null } },
      items: [],
      options: ["Lessons", "Records"],
      currentDate: new Date(),
    };
  },

  async mounted() {
    await this.init();
  },
  methods: {
    moment,
    PeriodFormat(DateIn, DateOut) {
      return Vue.filter("PeriodFormat")(DateIn, DateOut);
    },
    DataFromServerNormalize(dataSource) {
      return dataSource.map((d) => ({
        Id: d.CourseId,
        Name: d.Name,
        Subject: d.Subject?.Name,
        Status: d.Status,
        StatusText: `Пройдено ${d.Completed} из ${d.Total}`,
        Completed: d.Completed,
        Total: d.Total,
        Curators: d.Curators,
        Period: d.Period,
        DateStart: d.DateIn,
        IsAvailable: d.IsAvailable,
        IsLimited: d.IsLimited,
        IsNotified: d.IsNotified,
        Lessons: d.Lessons.map((l) => ({
          ParentId: d.CourseId,
          Id: l.Id,
          Name: l.Name,
          DateStart: d.DateIn,
          IsAvailable: d.IsAvailable,
          Subject: null,
          Status: l.Status,
          StatusText: `Пройдено ${l.Completed} из ${l.Total}`,
          Completed: l.Completed,
          Total: l.Total,
          Records: l.Records.map((r) => ({
            ParentId: d.CourseId,
            Id: r.Id,
            Name: r.Name,
            DateStart: d.DateIn,
            IsAvailable: d.IsAvailable,
            Subject: null,
            Status: null,
            StatusText: null,
            Type: r.Type,
          })),
        })),
      }));
    },
    handleClick(item) {
      if (item.IsAvailable === false) {
        this.$alert(
          `Данный курс доступен для изучения с ${moment(
            item.DateStart,
            "DD.MM.YYYY"
          ).format("DD.MM.YYYY")}`
        );
        return -1;
      } else if (!item.ParentId) {
        return item.Id;
      } else {
        return item.ParentId;
      }
    },
  },
};
</script>
<style lang="scss">
#knowledge-base__courses {
  .v-row-group__header
    + tr:not(.v-row-group__header):not(.base-table-full__add) {
    display: none;
  }
  .v-row-group__header {
    background-color: unset !important;
  }

  .td1 {
    width: 300px;
    &-wrap {
      display: flex;
      align-items: center;
    }
  }
  .td2 {
    width: 172px;
  }
  .td3 {
    width: 150px !important;
  }
  .td5 {
    width: 150px;
  }
}
</style>
