var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-list-view',{attrs:{"dataSource":_vm.dataSource,"headers":_vm.headers,"loading":_vm.loading,"options":_vm.options,"showDelete":false,"title":"Мои курсы","notShowActions":['add'],"notShowSelect":"","label-text-search":"Поиск курса","pageEditName":"KnowledgeBaseCourseEmployeeEdit","id":"knowledge-base__courses","funcHandleClickId":_vm.handleClick,"sort-by":"Name","exclude-settings-actions":['group'],"showFilters":true},on:{"onRefresh":_vm.init},scopedSlots:_vm._u([{key:"table",fn:function({ attrs, listeners }){return [_c('base-tree-table',_vm._g(_vm._b({attrs:{"items":_vm.dataSource,"fixedWidthActions":"","item-class":(item) => {
            return (
              (item.IsAvailable ? '' : 'disabled') +
              (item.IsNotified && 'font-weight-bold')
            );
          },"treeTableContents":""},scopedSlots:_vm._u([{key:"item.StatusText",fn:function({ item }){return [(!item.Type)?_c('div',{staticClass:"knowledge-base__status-point__wrapper"},[_c('span',{staticClass:"knowledge-base__status-point",class:'knowledge-base__status-point--' + item.Status}),_vm._v(" "+_vm._s(item.StatusText)+" ")]):_vm._e()]}},{key:"item.Period",fn:function({ item }){return [_c('div',{staticStyle:{"width":"150px"}},[(item.IsLimited)?[_c('span',{class:{
                  'error--text':
                    item.Status !== 3 &&
                    _vm.moment(item.Period.DateOut, 'DD.MM.YYYY') < _vm.moment(),
                }},[_vm._v(" "+_vm._s(_vm.PeriodFormat(item.Period.DateIn, item.Period.DateOut))+" ")])]:_vm._e()],2)]}},{key:"item.Curators",fn:function({ item }){return [_c('div',{staticStyle:{"width":"150px"}},[_vm._v(" "+_vm._s(item.Curators ? item.Curators.map((e) => _vm.$options.filters.PersonShortName(e) ).join(", ") : "")+" ")])]}},{key:"item.Subject",fn:function({ item }){return [_c('div',{staticClass:"td1-wrap"},[(item.Type && item.Type === 0)?_c('m-icon',{staticClass:"mr-4",attrs:{"icon":"medexpert-file-test"}}):_vm._e(),(item.Type && item.Type === 1)?_c('m-icon',{staticClass:"mr-4",attrs:{"icon":"medexpert-file-word"}}):_vm._e(),(item.Type && item.Type === 2)?_c('m-icon',{staticClass:"mr-4",attrs:{"icon":"medexpert-file-doc"}}):_vm._e(),(item.Type && item.Type === 3)?_c('m-icon',{staticClass:"mr-4",attrs:{"icon":"medexpert-file-video"}}):_vm._e(),(item.Subject && !item.Type)?_c('span',[_vm._v(_vm._s(item.Subject))]):_vm._e()],1)]}}],null,true)},'base-tree-table',attrs,false),listeners))]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }